export enum DisplayAlignment {
  Portrait,
  Landscape
}

export const DISPLAY_ALIGNMENTS = [
  { label : "Portrait",  value : DisplayAlignment.Portrait},
  { label : "Landscape", value : DisplayAlignment.Landscape}
];

export enum WallStyle {
  Straight,
  Curved
}

export const WALL_STYLES = [
  { label : "Straight",  value : WallStyle.Straight},
  { label : "Curved", value : WallStyle.Curved}
];

export enum Mounting {
  WallMounted,
  OpenFrame,
  NoMounting
}

export const MOUNTING_TYPES = [
  { label : "Wall Mounted",  value : Mounting.WallMounted},
  { label : "Open Frame", value : Mounting.OpenFrame},
  { label : "No Mounting", value : Mounting.NoMounting}
];

export enum Units {
  Imperial,
  Metric
}

export const UNITS = [
  { label : "Feet/inches/pounds",  value : Units.Imperial},
  { label : "Metric", value : Units.Metric}
];

export enum HydraType {
  Performance,
  Economy,
  None
}

export const HYDRA_TYPES = [
  { label : "Performance",  value : HydraType.Performance},
  { label : "Economy", value : HydraType.Economy},
  { label : "None", value : HydraType.None}
];

export enum DisplayType {
  MT557D
}

/* All measures are metric. */
export const mt557d = {
  width : 1216,
  height : 687,
  brightness : 700, // cd/m²
  weight : 39.1, // kg
  weightWithWallMount : 50.0,
  watts : 300,
  model : "MT557D",
  type : DisplayType.MT557D,
  surface : "Extra-white glass",
  surfaceTreatment : "Antimicrobial, anti-glare",
  url : "https://www.multitaction.com/decca/"
}

export const displays = [
  mt557d
];

export const DISPLAY_TYPES =
  displays.map((display) => ({ label : display.model, value : display.type }));

export enum ExampleConfig {
  Portrait_1x5,
  Portrait_1x15,
  Portrait_2x10,
  Landscape_2x3,
  Landscape_3x3,
  Landscape_3x4,
  Landscape_3x6,
  Landscape_5x9,
  None
}

export interface IWallParameters {
  spaceDefined : boolean,
  spaceWidth : number,
  spaceHeight : number,
  display : DisplayType,
  alignment: DisplayAlignment,
  rows: number,
  columns : number,
  style : WallStyle,
  mounting : Mounting,
  units : Units,
  cableRunDistance : number,
  hydra : HydraType,
  videoCaptureCount : number,
  extraVideoOutputs : number,
  onChange? : any,
  exampleConfig? : ExampleConfig
}

function inRange(value : number, min : number, max : number) {
  let tmp = Number(value);
  return tmp >= min && tmp <= max;
}

function containsValue(value : any, options : any) {
  let tmp = Number(value);
  for(let i = 0; i < options.length; i++) {
    if(options[i].value == tmp) return true;
  }
  return false;
}

function valueStr(value : any, options : any) {
  let tmp = Number(value);
  for(let i = 0; i < options.length; i++) {
    if(options[i].value == tmp) return options[i].label;
  }
  return "";
}

function round1Decimal(value : number) {
  return Math.round(value * 10) / 10;
}

export class IWall {
  parameters : IWallParameters;

  maxSpaceWidth = 50;
  maxSpaceHeight = 10;
  maxCableLength = 50;
  maxDisplays = 100;
  maxHydraOutputs = 48;

  constructor(parameters : IWallParameters) {
    this.parameters = parameters;
  }

  isValid() {

    if(!inRange(this.parameters.spaceWidth, 1, this.maxSpaceWidth)) return false;
    if(!inRange(this.parameters.spaceHeight, 1, this.maxSpaceHeight)) return false;

    if(this.parameters.alignment == DisplayAlignment.Portrait) {
      if(!inRange(this.parameters.rows, 1, 2)) return false;
    } else if(this.parameters.alignment == DisplayAlignment.Landscape) {
      if(!inRange(this.parameters.rows, 1, 5)) return false;
    } else {
      return false;
    }

    if(!inRange(this.parameters.columns, 1, Math.floor(this.maxDisplays / this.parameters.rows))) return false;

    if(!containsValue(this.parameters.style, WALL_STYLES)) return false;
    if(!containsValue(this.parameters.mounting, MOUNTING_TYPES)) return false;
    if(!containsValue(this.parameters.units, UNITS)) return false;
    if(!containsValue(this.parameters.hydra, HYDRA_TYPES)) return false;

    if(!inRange(this.parameters.cableRunDistance, 0, this.maxCableLength)) return false;

    if(!inRange(this.parameters.videoCaptureCount, 0, 8)) return false;
    if(!inRange(this.parameters.extraVideoOutputs, 0, 8)) return false;

    return true;
  }

  display() {
    /* our target is es5 for some reason, so there is no Array.prototype.find() */
    for(var i=0; i < displays.length; ++i)
      if(displays[i].type == this.parameters.display)
        return displays[i];
    return displays[0];
  }

  displayDimensions() {

    let dp = this.display();

    if(this.parameters.alignment == DisplayAlignment.Landscape)
      return dp;
    else
      return { width : dp.height, height : dp.width };
  }

  wallDimensions() {
    let cell = this.displayDimensions();
    return { width : cell.width * this.parameters.columns, height : cell.height * this.parameters.rows };
  }

  wallPixels() {
    if(this.parameters.alignment == DisplayAlignment.Landscape) {
      return {
         width : 1920 * this.parameters.columns,
         height : 1080 * this.parameters.rows
      }
    } else {
      return {
         width : 1080 * this.parameters.columns,
         height : 1920 * this.parameters.rows
      }
    }
  }

  displayCount() {
    return this.parameters.rows * this.parameters.columns;
  }

  megaPixels() {
    return this.displayCount() * 1920 * 1080 / 1000000;
  }

  diagonalInches() {
    let dims = this.wallDimensions();
    let diagonalMM = Math.sqrt(dims.width * dims.width + dims.height * dims.height);
    return diagonalMM * 0.039370079;
  }

  powerMax() {
    return this.displayCount() * this.display().watts;
  }

  btuPerHourMax() {
    return this.powerMax() * 3.412141633;
  }

  extenderCount() {
    if(this.parameters.cableRunDistance <= 1)
      return 0;
    return this.displayCount();
  }

  bottomFromFloorMM() {
    if(this.parameters.alignment == DisplayAlignment.Landscape) {
      if(this.parameters.rows == 1) {
        return 1000;
      } else if(this.parameters.rows == 2) {
        return 800;
      } else if(this.parameters.rows == 3) {
        return 380;
      } else {
        return 100;
      }
    }
    else {
      if(this.parameters.rows == 1) {
        return 800;
      } else {
        return 300;
      }
    }
  }

  bottomClearanceFromFloorMM() {
    return this.bottomFromFloorMM() - this.margin();
  }

  topClearanceFromFloorMM() {
    return this.topFromFloorMM() + this.margin();
  }

  topFromFloorMM() {
    return this.bottomFromFloorMM() + this.wallDimensions().height;
  }

  hydraLabel() {
    if(this.parameters.hydra == HydraType.Performance)
      return "Hydra Performance";
    else if(this.parameters.hydra == HydraType.Economy)
      return "Hydra Economy";
    return "None";
  }

  hydraOutputs() {
    let displayCount = this.displayCount() + Math.round(this.parameters.extraVideoOutputs);

    const outputSizes = [2, 4, 8, 12, 16];
    for(let i = 0; i < outputSizes.length; i++) {
      if(displayCount <= outputSizes[i]) {
        return outputSizes[i];
      }
    }
    return 16;
  }

  hydraCaptureCards() {
    return Math.ceil(this.parameters.videoCaptureCount * 0.25);
  }

  hydraInputs() {
    return this.hydraCaptureCards() * 4;
  }

  hydraGPUs() {
    let outputN = this.hydraOutputs();
    return Math.ceil(outputN * 0.25);
  }

  hydraPower() {
    let gpus = this.hydraGPUs();
    // The GPU power includes losses in the PSU
    let gpuWatts = (this.parameters.hydra == HydraType.Performance) ? 270 : 120;
    let baseWatts = 250;
    return gpuWatts * gpus + baseWatts;
  }

  hydraBTUPerHour() {
    return this.hydraPower() * 3.412141633;
  }

  // Number of Datapath FX4 splitters in the iWall
  splitterCount() {
    let outputs = this.displayCount() + Number(this.parameters.extraVideoOutputs);

    if(this.hydraOutputs() >= outputs)
      return 0;

    let forExtraDisplays = Math.ceil(this.parameters.extraVideoOutputs / 4);

    if(this.parameters.rows == 3) {
      return Math.round(this.parameters.columns) + forExtraDisplays;
    } else if(this.parameters.rows == 5) {
      // Rows 1-4 with one splitter per column
      const row14splitters : number = Number(this.parameters.columns);
      // Rows 5 with one splitter per four columns
      const row5splitters : number = Math.ceil(this.parameters.columns / 4);
      return row14splitters + row5splitters + forExtraDisplays;
    } else {
      // rows = 1, 2 or 4
      return Math.ceil(this.displayCount() / 4) + forExtraDisplays;
    }
  }

  videoCaptureCount() {
    return (this.parameters.hydra != HydraType.None) ? this.parameters.videoCaptureCount : 0;
  }

  extraVideoOutputs() {
    return (this.parameters.hydra != HydraType.None) ? this.parameters.extraVideoOutputs : 0;
  }

  mmOrInchLabel(mm : number) {
    if(this.parameters.units == Units.Metric)
      return mm + " mm";
    else
      return Math.round(mm * 0.3937008) / 10 + " inches";
  }

  wallMountedWeightLabel() {
    let w = this.display().weightWithWallMount;
    let unit = "kg";
    // Pre-round when converting to imperial for UI purposes,
    // so that 5*toPounds(50kg) == 550lb for example.
    if(this.parameters.units != Units.Metric) {
      w = Math.round(2.20462262185 * w);
      unit = "lb";
    }

    return (this.displayCount() * w) + " " + unit + " (includes wall mount)";
  }

  // Margin for ventilation, working with screwdriver etc.
  margin() {
    return 80;
  }

  isTooWide() {
    return this.parameters.spaceWidth < ((Number(this.wallDimensions().width) + this.margin() * 2) / 1000)
  }

  isTooTall() {
    return this.parameters.spaceHeight < ((this.wallDimensions().height + this.bottomFromFloorMM() + this.margin()) / 1000)
  }

  mountingStr() {
    return valueStr(this.parameters.mounting, MOUNTING_TYPES);
  }

  hydraStr() {
    return valueStr(this.parameters.hydra, HYDRA_TYPES)
  }

  cableRunStr() {
    return (this.parameters.units == Units.Metric) ?
      round1Decimal(this.parameters.cableRunDistance) + " meters" :
      round1Decimal(3.28084 * Number(this.parameters.cableRunDistance)) + " feet";
  }

  labelStr() {
    return "iWall " + WALL_STYLES[this.parameters.style].label + " " +
      this.parameters.rows + "x" + this.parameters.columns + " " + this.display().model +
      " - " + this.mountingStr() +
      (this.parameters.alignment == DisplayAlignment.Portrait ? " - Portrait" : " - Landscape");
  }
}

export const EXAMPLE_CONFIGS = [
  { label : "Portrait 1 x 5",  value : ExampleConfig.Portrait_1x5},
  { label : "Portrait 1 x 15",  value : ExampleConfig.Portrait_1x15},
  { label : "Portrait 2 x 10",  value : ExampleConfig.Portrait_2x10},
  { label : "Landscape 2 x 3",  value : ExampleConfig.Landscape_2x3},
  { label : "Landscape 3 x 3",  value : ExampleConfig.Landscape_3x3},
  { label : "Landscape 3 x 4",  value : ExampleConfig.Landscape_3x4},
  { label : "Landscape 3 x 6",  value : ExampleConfig.Landscape_3x6},
  { label : "Landscape 5 x 9",  value : ExampleConfig.Landscape_5x9},
  { label : "Not selected",  value : ExampleConfig.None}
]

export function exampleConfig(ex : ExampleConfig) {
  let rows = 1;
  let columns = 1;
  let alignment = DisplayAlignment.Landscape;
  let mounting = Mounting.OpenFrame;

  if(ex == ExampleConfig.Portrait_1x5) {
    alignment = DisplayAlignment.Portrait;
    rows = 1;
    columns = 5;
  } else if(ex == ExampleConfig.Portrait_1x15) {
    alignment = DisplayAlignment.Portrait;
    rows = 1;
    columns = 15;
  } else if(ex == ExampleConfig.Portrait_2x10) {
    alignment = DisplayAlignment.Portrait;
    rows = 2;
    columns = 10;
    mounting = Mounting.NoMounting;
  } else if(ex == ExampleConfig.Landscape_2x3) {
    rows = 2;
    columns = 3;
  } else if(ex == ExampleConfig.Landscape_3x3) {
    rows = 3;
    columns = 3;
  } else if(ex == ExampleConfig.Landscape_3x4) {
    rows = 3;
    columns = 4;
  } else if(ex == ExampleConfig.Landscape_3x6) {
    rows = 3;
    columns = 6;
  } else if(ex == ExampleConfig.Landscape_5x9) {
    rows = 5;
    columns = 9;
    mounting = Mounting.NoMounting;
  }

  let config : IWallParameters = {
    spaceDefined : true,
    spaceWidth : 1,
    spaceHeight : 1,
    display : DisplayType.MT557D,
    alignment: alignment,
    rows: rows,
    columns : columns,
    style : WallStyle.Straight,
    mounting : mounting,
    units : Units.Imperial,
    cableRunDistance : 0,
    hydra : HydraType.Performance,
    videoCaptureCount : 0,
    extraVideoOutputs : 0,
    exampleConfig : ex
  }

  let iwall = new IWall(config);
  let dims = iwall.wallDimensions();

  config.spaceWidth = Math.max(3, dims.width / 1000 + 2);
  config.spaceHeight = Math.max(2.5, dims.height / 1000 + 1.2);

  return config;
}
