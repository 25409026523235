import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Button, Dialog, HTMLSelect, Intent, NumericInput,
  Slider, Spinner, Switch, Classes, Tag, TextArea, Tooltip } from "@blueprintjs/core";

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

import { DisplayAlignment, DisplayType, WallStyle, Mounting, Units, HydraType, IWallParameters, IWall,
  ExampleConfig, EXAMPLE_CONFIGS, exampleConfig,
  DISPLAY_ALIGNMENTS, DISPLAY_TYPES, WALL_STYLES, HYDRA_TYPES, UNITS} from "../js/iwall-types";

function getUrlString(name : string, defaultValue : string) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(location.search);
  return results === null ? defaultValue : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function getUrlBoolean(name : string, defaultValue : boolean) {
  let v = getUrlString(name, defaultValue.toString());
  return v == "true";
};

function getUrlNumber(name : string, defaultValue : number) {
  let v = getUrlString(name, defaultValue.toString());
  return parseFloat(v);
};

interface MyNumberControlProps {
  useSlider : boolean,
  min : number,
  max : number,
  value : number,
  stepSize : number,
  onRelease : any
};

interface MyNumberControlState {
  value : number
}

/* This helper class wraps Blueprintjs.Slider. The problem with the default Slider
 * is that the slider indicator does not move unless the host moves it.
 * This class implements that movement, so that the host object can wait until the
 * slider is released, before receiving an update.
 */
export class MyNumberControl extends React.Component<MyNumberControlProps, MyNumberControlState> {
  rendered : Date;

  constructor(props : MyNumberControlProps) {
    super(props);
    this.state = {
      value : props.value,
    };
  }

  componentDidUpdate(prevProps : MyNumberControlProps) {
    console.log("MyNumberControl componentDidUpdate : ", this.props.value);
    /* if(this.state.value != this.props.value)
      this.setState({ value : this.props.value }); */
  }

  render() {
    console.log("MyNumberControl value =", this.state.value, this.props.min, this.props.max);
    if(this.props.useSlider) {
      return <Slider min={this.props.min} max={this.props.max}
        stepSize={this.props.stepSize} labelStepSize={this.props.max-this.props.min}
        value={this.state.value} onChange={this.handleChange} onRelease={this.handleRelease}
        />
    } else {
      return <NumericInput min={this.props.min} max={this.props.max} stepSize={this.props.stepSize}
        value={Math.round(this.props.value * 10) / 10} onValueChange={this.handleValueChange} />
    }
  }

  handleValueChange = (value : number, str : string) => {
    // this.setState({ value : value});

    console.log("MyNumberControl::handleValueChange # ", value);
    if(value <= this.props.max && value >= this.props.min && value != this.props.value)
      return this.props.onRelease(value);
  }

  handleChange = (value : number) => {
    return this.setState({ value : value});
  }

  handleRelease = (value : number) => {
    return this.props.onRelease(value);
  }
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

interface HelpTagProps {
  text : string
}

export class HelpTag extends React.Component<HelpTagProps, {}> {
  constructor(props : HelpTagProps) {
    super(props);
  }

  render() {
    return <Tooltip content={this.props.text}><Tag minimal={true} round={true}>?</Tag></Tooltip>
  }
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export class IWallParameterUI extends React.Component<IWallParameters, IWallParameters> {

  maxDisplays = 1;
  maxHydraOutputs = 1;

  constructor(props: IWallParameters) {
    super(props);
    this.state = props;
    // Cache some constants
    let iWallTmp = new IWall(this.state);
    this.maxDisplays = iWallTmp.maxDisplays;
    this.maxHydraOutputs = iWallTmp.maxHydraOutputs;
  }

  componentDidMount() {
    console.log("IWallParameterUI.componentDidMount");
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    console.log("IWallParameterUI.componentDidUpdate");
    if(this.state.onChange != null)
      this.state.onChange(this.state);
  }

  render() {
    console.log(this.state);
    let iwall = new IWall(this.state);

    let lengthUnit = this.state.units == Units.Metric ? "meters" : "feet";
    let useSliders = false;
    let controlClass = useSliders ? "slider" : "numberbox";

    return (<div className="iwall-parameters">
      <table><tbody>

      <tr><td><h3>Examples <HelpTag text="Choose one of these example setups to get ideas for your iWall"/></h3></td>
        <td><HTMLSelect value={this.state.exampleConfig} onChange={this.handleExample}
          options={EXAMPLE_CONFIGS} />
      </td></tr>

      <tr><td><h3>Units <HelpTag text="Select the units to be used for parameters and specifications"/></h3></td>
        <td><HTMLSelect value={this.state.units} onChange={this.handleUnits}
          options={UNITS} />
      </td></tr>

      <tr><td colSpan={2} className="new-section"><h3>Available space <HelpTag text="Specify the size of the wall (or space) behind the iWall" /></h3></td></tr>

      <tr><td colSpan={1}>Width ({lengthUnit})</td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={this.toLengthUnits(2)} max={this.toLengthUnits(iwall.maxSpaceWidth)} stepSize={0.1}
            value={this.toLengthUnits(this.state.spaceWidth)} onRelease={this.handleSpaceWidth} useSlider={useSliders}/>
      </td></tr>

      <tr><td colSpan={1}>Height ({lengthUnit})</td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={this.toLengthUnits(2)} max={this.toLengthUnits(iwall.maxSpaceHeight)} stepSize={0.1}
            value={this.toLengthUnits(this.state.spaceHeight)} onRelease={this.handleSpaceHeight} useSlider={useSliders}/>
      </td></tr>

      <tr><td colSpan={2} className="new-section"><h3>iWall <HelpTag text="Set the parameters for the iWall here" /></h3></td></tr>

      <tr><td>Display model <HelpTag text="Choose the MultiTaction display for the touch video wall" /></td>
        <td><HTMLSelect value={this.state.display} onChange={this.handleDisplay}
          options={DISPLAY_TYPES} />
      </td></tr>

      <tr><td>Display orientation</td>
        <td><HTMLSelect value={this.state.alignment} onChange={this.handleAlignment}
          options={DISPLAY_ALIGNMENTS} />
      </td></tr>

      <tr><td colSpan={1}>Rows <HelpTag text="The number of display rows in the iWall" /></td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={1} max={this.maxRows(this.state.alignment)} stepSize={1}
            value={this.state.rows} onRelease={this.handleRows} useSlider={useSliders}/>
      </td></tr>

      <tr><td colSpan={1}>Columns <HelpTag text="The number of display columns in the iWall" /></td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={1} max={this.maxColumns(0)} stepSize={1}
            value={this.state.columns} onRelease={this.handleColumns} useSlider={useSliders}/>
      </td></tr>

      <tr><td>Style <HelpTag text="Choose between curved and straight setups" /></td>
        <td><HTMLSelect value={this.state.style} onChange={this.handleStyle}
            options={WALL_STYLES} />
      </td></tr>

      <tr><td>Mounting <HelpTag text="Choose the mounting style. Ready-made mounts/frames are not available for all layouts." /></td>
        <td><HTMLSelect value={this.state.mounting} onChange={this.handleMounting}
            options={this.listMountings()} />
      </td></tr>

      <tr><td colSpan={1}>Cable run ({lengthUnit}) <HelpTag text="If the computer is located away from the iWall, indicate the distance here" /></td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={0} max={this.toLengthUnits(iwall.maxCableLength)} stepSize={0.1}
            value={this.toLengthUnits(this.state.cableRunDistance)} onRelease={this.handleCableRunDistance} useSlider={useSliders}/>
      </td></tr>

      <tr><td colSpan={2} className="new-section"><h3>Application Computer <HelpTag text="Select the type of computer to drive the iWall" /></h3></td></tr>

      <tr><td>Hydra appliance <HelpTag text="Select a computer to be used to drive the iWall" /></td>
        <td><HTMLSelect value={this.state.hydra} onChange={this.handleHydra}
            options={this.listHydras()} />
      </td></tr>

      <tr><td colSpan={1}>Video capture ports <HelpTag text="The number of full-HD video capture inputs in the computer" /></td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={0} max={8} stepSize={1}
            value={this.state.videoCaptureCount} onRelease={this.handleVideoCapture} useSlider={useSliders}/>
      </td></tr>

      <tr><td colSpan={1}>Extra video outputs <HelpTag text="The number of extra video outputs for projectors, video conferencing etc." /></td>
        <td colSpan={1} className={controlClass}><MyNumberControl min={0} max={8} stepSize={1}
            value={this.state.extraVideoOutputs} onRelease={this.handleExtraOutputs} useSlider={useSliders}/>
      </td></tr>

      </tbody></table></div>
   );
  }

  handleExample = (event : any) => {
    let ex : ExampleConfig = event.target.value;
    this.setState(exampleConfig(ex));
  }

  handleUnits = (event : any) => {
    let units : Units = event.target.value;
    console.log("handleUnits -> ", units);
    this.setState({ units : units});
  }

  handleSpaceWidth = (width : number) => {
    console.log("handleSpaceWidth -> ", width, " ", this.toMeters(width));
    this.setState({spaceWidth : this.toMeters(width) });
    this.modified();
  }

  handleSpaceHeight = (height : number) => {
    console.log("handleSpaceHeight -> ", height, " ", this.toMeters(height));
    this.setState({spaceHeight : this.toMeters(height) });
    this.modified();
  }

  handleDisplay = (event : any) => {
    let dt : DisplayType = event.target.value;
    this.setState({ display : dt });
  }

  handleAlignment = (event : any) => {
    console.log("handleAlignment -> ", event.target.value);
    let ali = event.target.value as DisplayAlignment;
    this.setState({alignment : ali});
    let maxRows = this.maxRows(ali);
    if(this.state.rows > maxRows) {
      this.setState({ rows : maxRows});
      this.ensureMounting(this.state.style, maxRows, ali);
    } else {
      this.ensureMounting(this.state.style, this.state.rows, ali);
    }
    this.modified()
    return true;
  }

  handleRows = (value : number) => {
    console.log("handleRows -> ", value);
    this.ensureHydraPc(value, this.state.columns);
    this.setState({ rows : value });
    if(this.state.columns > this.maxColumns(value)) {
      console.log("handleRows to ", this.maxColumns(value));
      this.setState({ columns : this.maxColumns(value)});
    }

    this.ensureMounting(this.state.style, value, this.state.alignment);
    this.modified();
    return true;
  }

  handleColumns = (value : number) => {
    console.log("handleColumns -> ", value);
    this.ensureHydraPc(this.state.rows, value);
    this.setState({ columns : value as number});
    this.modified();
    return true;
  }

  handleStyle = (event : any) => {
    console.log("handleStyle -> ", event.target.value);
    let style = event.target.value as WallStyle;
    this.setState({ style : style});
    this.ensureMounting(style, this.state.rows, this.state.alignment);
    this.modified();
    return true;
  }

  handleMounting = (event : any) => {
    console.log("handleMounting -> ", event.target.value);
    let mount = event.target.value as Mounting;
    this.setState({ mounting : mount as Mounting});
    this.modified();
    return true;
  }

  handleHydra = (event : any) => {
    console.log("handleHydra -> ", event.target.value);
    let hydra = event.target.value as HydraType;
    this.setState({ hydra : hydra});
    this.modified();
    return true;
  }

  handleVideoCapture = (value : number) => {
    console.log("handleVideoCapture -> ", value);
    this.setState({ videoCaptureCount : value});
    this.modified();
    return true;
  }

  handleExtraOutputs = (value : number) => {
    console.log("handleExtraOutputs -> ", value);
    this.setState({ extraVideoOutputs : value});
    this.modified();
    return true;
  }

  handleCableRunDistance = (value : number) => {
    console.log("handleCableRunDistance -> ", this.toMeters(value));
    this.setState({ cableRunDistance : this.toMeters(value) });
    this.modified();
    return true;
  }

  modified() {
    console.log("modified");
    this.setState({ exampleConfig : ExampleConfig.None })
  }

  availableMountings(style : WallStyle, rows : number, align : DisplayAlignment) {
    let available : Mounting[] = [Mounting.NoMounting];

    if(style == WallStyle.Straight) {
      if(align == DisplayAlignment.Portrait) {
        if(rows == 1) {
          available.push(Mounting.WallMounted);
          available.push(Mounting.OpenFrame);
        }
      } else {
        available.push(Mounting.WallMounted);
        if(rows <= 5)
          available.push(Mounting.OpenFrame);
      }
    } else {
      if(align == DisplayAlignment.Portrait) {
        if(rows == 1)
          available.push(Mounting.OpenFrame);
      } else {
        if(rows <= 4)
          available.push(Mounting.OpenFrame);
      }
    }

    return available;
  }

  listMountings() {

    let available =
      this.availableMountings(this.state.style, this.state.rows, this.state.alignment);

    return [
      { label : "Wall mounted",  value : Mounting.WallMounted,
        disabled : available.indexOf(Mounting.WallMounted) < 0},
      { label : "Open frame", value : Mounting.OpenFrame,
        disabled : available.indexOf(Mounting.OpenFrame) < 0},
      { label : "No mounting",  value : Mounting.NoMounting,
        disabled : available.indexOf(Mounting.NoMounting) < 0}
    ]
  }

  listHydras() {
    let available = (this.state.rows * this.state.columns) <= this.maxHydraOutputs;

    return [
      { label : "Performance",  value : HydraType.Performance, disabled : !available },
      { label : "Economy", value : HydraType.Economy, disabled : !available },
      { label : "None",  value : HydraType.None, disabled : false }];
  }

  isMountingAvailable(available : Mounting[], mount : Mounting) {
    for(let i = 0; i < available.length; i++) {
      if(available[i] == mount) return true;
    }
    return false;
  }

  ensureMounting(style : WallStyle, rows : number, align : DisplayAlignment) {
    let available = this.availableMountings(style, rows, align);
    console.log("ensureMounting : ", available, " match ", this.state.mounting);
    if(!this.isMountingAvailable(available, this.state.mounting)) {
      console.log("ensureMounting : Demounting ", available.indexOf(this.state.mounting));
      this.setState({ mounting : Mounting.NoMounting});
    }
  }

  ensureHydraPc(rows : number, columns : number) {
    let total = rows * columns;
    if(total > this.maxHydraOutputs) {
      this.setState({ hydra : HydraType.None});
    }
  }

  maxRows(align : DisplayAlignment) {
    return (align == DisplayAlignment.Portrait) ? 2 : 5;
  }

  maxColumns(value : number) {
    if(value > 0)
      return Math.floor(this.maxDisplays / value);
    else
      return Math.floor(this.maxDisplays / this.state.rows);
  }

  toLengthUnits(meters : number) {
    return (this.state.units == Units.Imperial) ? (3.28084 * meters) : meters;
  }

  toMeters(lengthUnits : number) {
    return (this.state.units == Units.Imperial) ? (lengthUnits / 3.28084) : lengthUnits;
  }

}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

interface IWallVisualizationParameters extends IWallParameters {
  showMeasures : boolean,
  showPeople : boolean
}

class IWallVisualization extends React.Component<IWallVisualizationParameters, IWallVisualizationParameters> {

  constructor(props : IWallVisualizationParameters) {
    super(props);
    this.state = props;
  }

  render() {
    setTimeout(() => { this.repaint()}, 100);
    return (<div className="iwall-visualization">
      <div className="hide-in-print">
        <Switch checked={this.state.showMeasures} label="Show measures" onChange={this.handleShowMeasures} inline={true} />
        <Switch checked={this.state.showPeople} label="Show people" onChange={this.handleShowPeople} inline={true} />
      </div>
      <canvas ref="canvas" />
      <img ref="manSilhouette" src="/images/man-silhouette.png" className="hidden" />
      <img ref="womanSilhouette" src="/images/woman-silhouette.png" className="hidden" />
      </div>);
  }

  componentDidMount() {
    this.repaint();
    const img : any = this.refs.manSilhouette;
    img.onload = () => {
      this.repaint();
    }

    const img2 : any = this.refs.womanSilhouette;
    img2.onload = () => {
      this.repaint();
    }

    window.addEventListener('resize', () => { this.repaint() })
  }

  handleShowMeasures = (event: React.FormEvent<HTMLElement>) => {
    console.log("handleShowMeasures -> ", (event.target as HTMLInputElement).checked);
    this.setState({ showMeasures : (event.target as HTMLInputElement).checked });
  }

  handleShowPeople = (event: React.FormEvent<HTMLElement>) => {
    console.log("handleShowPeople -> ", (event.target as HTMLInputElement).checked);
    this.setState({ showPeople : (event.target as HTMLInputElement).checked });
  }

  repaint() {

    let iwall = new IWall(this.state);
    console.log("repainting");
    const canvas : any = this.refs.canvas;
    const w : number = canvas.clientWidth;
    const h : number = canvas.clientWidth * 0.6; // Fixed aspect ratio

    canvas.width = w;
    canvas.height = h;

    let space = {
      width  : this.state.spaceWidth * 1000,
      height : this.state.spaceHeight * 1000
    }

    let spaceHalf = {
      width : space.width * 0.5,
      height : space.height * 0.5
    }

    let roomCorners = {
      x : space.width * 15,
      y : space.height * 10
    }

    const wh = iwall.displayDimensions();
    const iwh = iwall.wallDimensions();

    const ctx = canvas.getContext("2d");
    const margin = Math.max(30, w * 0.1);
    let wscale = (w - 2 * margin) / Math.max(iwh.width, space.width);
    let hscale = (h - 2 * margin) / Math.max(iwh.height, space.height);
    let scale = Math.min(wscale,hscale);

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.translate(w * 0.5, h * 0.5);
    ctx.scale(scale, scale);

    ctx.fillStyle = '#333';
    ctx.fillRect(-spaceHalf.width, -spaceHalf.height, space.width, space.height);

    ctx.fillStyle = '#ddd';
    // Ceiling
    ctx.moveTo(-roomCorners.x, -roomCorners.y);
    ctx.lineTo(-spaceHalf.width, -spaceHalf.height);
    ctx.lineTo( spaceHalf.width, -spaceHalf.height);
    ctx.lineTo( roomCorners.x, -roomCorners.y);
    ctx.closePath();

    // Floor
    ctx.moveTo(-roomCorners.x, roomCorners.y);
    ctx.lineTo(-spaceHalf.width, spaceHalf.height);
    ctx.lineTo( spaceHalf.width, spaceHalf.height);
    ctx.lineTo( roomCorners.x, roomCorners.y);
    ctx.closePath();

    ctx.fill();

    let floorAt = space.height * 0.5;
    let bottomAt = floorAt - iwall.bottomFromFloorMM();

    let drawWidth = wh.width - 5;
    let drawHeight = wh.height - 5;
    let frameBottom = floorAt + 50;

    for(let j = 0; j < this.state.columns; j++) {
      let left = iwh.width * -0.5 +  j * wh.width;
      let right = left + wh.width;
      for(let i = 0; i < this.state.rows; i++) {
        let top = bottomAt - (i+1) * wh.height;

        if(top <= (floorAt - space.height) || left < -spaceHalf.width || right > spaceHalf.width) {
          ctx.fillStyle = '#c44';
        } else {
          ctx.fillStyle = '#36A9E1';
        }
        ctx.fillRect(left, top, drawWidth, drawHeight);
      }

      if(iwall.parameters.mounting == Mounting.OpenFrame) {
        ctx.fillStyle = '#888';
        ctx.fillRect(left, bottomAt, 40, -(bottomAt - frameBottom));
        ctx.fillRect(left + drawWidth, bottomAt, -40, -(bottomAt - frameBottom));
      }
    }

    if(this.state.showMeasures) {
      console.log("Rendering measures");

      let left = iwh.width * -0.5 - iwall.margin() * 2;
      let right = -left;
      ctx.lineWidth = 1.5 / scale;
      ctx.strokeStyle = '#F38';
      ctx.strokeRect(iwh.width * -0.5 - iwall.margin(),
        floorAt - iwh.height - iwall.bottomFromFloorMM() - iwall.margin(),
        iwh.width + iwall.margin() * 2, iwh.height + iwall.margin() * 2);

      this.drawVerticalMeasure(ctx, scale, left, floorAt, floorAt - iwall.bottomFromFloorMM(), iwall.bottomFromFloorMM());
      this.drawVerticalMeasure(ctx, scale, left,
          floorAt - iwall.topFromFloorMM(), floorAt - iwall.bottomFromFloorMM(), iwh.height);

      this.drawVerticalMeasure(ctx, scale, right,
          floorAt, floorAt - iwall.bottomClearanceFromFloorMM(), iwall.bottomClearanceFromFloorMM());
      this.drawVerticalMeasure(ctx, scale, right,
          floorAt - iwall.topClearanceFromFloorMM(), floorAt - iwall.bottomClearanceFromFloorMM(),
          iwh.height + iwall.margin() * 2);

      this.drawHorizontalMeasure(ctx, scale, -0.5 * iwh.width, 0.5 * iwh.width,
          floorAt - iwall.topFromFloorMM() + 30 / scale, iwh.width);
      this.drawHorizontalMeasure(ctx, scale, -0.5 * iwh.width - iwall.margin(), 0.5 * iwh.width + iwall.margin(),
          floorAt - iwall.topClearanceFromFloorMM() - 10 / scale, iwh.width + iwall.margin() * 2);
      }

    if(this.state.showPeople) {
      const img = this.refs.manSilhouette;
      let manHeight = 1750;
      let manWidth = manHeight * 0.6;
      let manBottom = frameBottom + 30;
      ctx.drawImage(img, 0, manBottom - manHeight, manWidth, manHeight)

      const img2 = this.refs.womanSilhouette;
      let womanHeight = 1750;
      let womanWidth = womanHeight * 0.5;
      let womanBottom = frameBottom + 30;
      ctx.drawImage(img2, womanWidth * -1, womanBottom - womanHeight, womanWidth, womanHeight)
    }
  }

  distanceToLabel(valueMM : number) {
    if(this.state.units == Units.Imperial)
      return (Math.round(3.28084 * valueMM / 10) / 100) + " feet";
    else
      return Math.round(valueMM) + " mm";
  }

  drawVerticalMeasure(ctx : any, scale : number, x : number, y1 : number, y2 : number, valueMM : number) {
    let label : string = this.distanceToLabel(valueMM);
    ctx.strokeStyle = 'white';
    ctx.fillStyle = 'white';
    let capWidth = 5 / scale;

    ctx.beginPath();
    ctx.moveTo(x, y1);
    ctx.lineTo(x, y2);
    ctx.moveTo(x - capWidth, y1);
    ctx.lineTo(x + capWidth, y1);
    ctx.moveTo(x - capWidth, y2);
    ctx.lineTo(x + capWidth, y2);
    ctx.stroke();

    ctx.font = 13 / scale + 'px Arial';

    if(x < 0) {
      let metrics = ctx.measureText(label);
      ctx.fillText(label, x - metrics.width - 10 / scale, (y1 + y2) * 0.5);
    }
    else
      ctx.fillText(label, x + 10 / scale, (y1 + y2) * 0.5);
  }

  drawHorizontalMeasure(ctx : any, scale : number, x1 : number, x2 : number, y : number, valueMM : number) {
    let label : string = this.distanceToLabel(valueMM);
    ctx.strokeStyle = 'white';
    ctx.fillStyle = 'white';
    let capWidth = 5 / scale;

    ctx.beginPath();
    ctx.moveTo(x1, y);
    ctx.lineTo(x2, y);
    ctx.moveTo(x1, y - capWidth);
    ctx.lineTo(x1, y + capWidth);
    ctx.moveTo(x2, y - capWidth);
    ctx.lineTo(x2, y + capWidth);
    ctx.stroke();

    ctx.font = 13 / scale + 'px Arial';

    let metrics = ctx.measureText(label);
    ctx.fillText(label, -0.5 * metrics.width, y - 10 / scale);
  }
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

class IWallSummary extends React.Component<{}, IWallParameters> {

  componentDidUpdate() {
    this.componentDidMount();
  }

  componentDidMount() {

    console.log("IWallSummary.componentDidMount");
    if(!this.state || !this.state.rows)
      return;

    $.ajax({
      url : '/iwall/api/summary',
      type : 'get',
      data : this.state,
      success : function(data) {
        let summaryDiv = $('.iwall-summary');
        summaryDiv.html(data);
      },
      error : function (xhr, ajaxOptions, thrownError) {
        var errorMsg = 'Ajax request failed: ' + xhr.responseText;
        let summaryDiv = $('.iwall-summary');
        summaryDiv.html(errorMsg);
      }
    });
  }

  render() {
    return <div className="iwall-summary"><h3>Summary</h3></div>
  }
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

interface IWallBuilderState {
  requestQuoteDialogOpen : boolean,
  requestQuoteConfirmOpen : boolean,
  requestQuoteConfirmText : string,
  requestQuoteEmail : string,
  requestQuoteName : string,
  requestQuoteTelephone : string,
  requestQuoteCountry : string,
  requestQuoteState : string,
  requestQuoteProjectName : string,
  requestQuoteNotes : string
}

class IWallBuilder extends React.Component {

  config : IWallParameters;

  state : IWallBuilderState = {
    requestQuoteDialogOpen : false,
    requestQuoteConfirmOpen : false,
    requestQuoteConfirmText : "",
    requestQuoteEmail : "",
    requestQuoteName : "",
    requestQuoteTelephone : "",
    requestQuoteCountry : "",
    requestQuoteState : "",
    requestQuoteProjectName : "",
    requestQuoteNotes : ""
  }

  constructor(props : any) {
    super(props);

    this.config = {
      spaceDefined : getUrlBoolean("spaceDefined", true),
      spaceWidth : getUrlNumber("spaceWidth", 6),
      spaceHeight : getUrlNumber("spaceHeight", 2.5),
      display : getUrlNumber("display", DisplayType.MT557D) as DisplayType,
      alignment: getUrlNumber("alignment", DisplayAlignment.Portrait) as DisplayAlignment,
      rows: getUrlNumber("rows", 0),
      columns : getUrlNumber("columns", 0),
      style : getUrlNumber("style", WallStyle.Straight) as WallStyle,
      mounting : getUrlNumber("mounting", Mounting.OpenFrame) as Mounting,
      units : getUrlNumber("units", Units.Imperial) as Units,
      cableRunDistance : getUrlNumber("cableRunDistance", 0),
      hydra : getUrlNumber("hydra", HydraType.Performance) as HydraType,
      videoCaptureCount : getUrlNumber("videoCaptureCount", 0),
      extraVideoOutputs : getUrlNumber("extraVideoOutputs", 0)
    }

    let iwall = new IWall(this.config);

    if(!iwall.isValid()) {
      this.config = exampleConfig(ExampleConfig.Portrait_1x5);
    } else {
      this.config.exampleConfig = ExampleConfig.None;
    }
    let self = this;
    $.ajax({
      url : '/account/api/user-info',
      type : 'get',
      success : function(data) {
        console.log("Received userinfo from API", data);
        if(data.loggedIn) {
          self.setState({
            requestQuoteEmail : data.email,
            requestQuoteName : data.name
          });
        }
      }
    });
  }

  getFromState(name : string, filler : string) {
    let some : any = this.state; // Overcome TS error reporting
    let tmp : string = String(some[name]);
    if(tmp && tmp.length) return tmp;
    return filler;
  }

  render() {
    let quoteParametersReady : boolean =
      this.state.requestQuoteEmail.length > 0 &&
      this.state.requestQuoteName.length > 0 &&
      this.state.requestQuoteCountry.length > 0 &&
      this.state.requestQuoteProjectName.length > 0;
    return (
      <div className="container">
        <IWallParameterUI ref="parameters" onChange={this.newConfiguration} {...this.config} />
        <IWallVisualization ref="visualization" showMeasures={false} showPeople={true} {...this.config} />
        <IWallSummary ref="summary" />
        <div className="dialog-buttons hide-in-print">
          <Button text="Request quote" onClick={this.requestQuote} intent={Intent.PRIMARY} />
          <Button text="Print" onClick={() => { window.print()} } intent={Intent.SUCCESS} />
        </div>
        <Dialog isOpen={this.state.requestQuoteDialogOpen}>
          <div className={Classes.DIALOG_BODY}>
            <h3>Request a quotation for this iWall</h3>
            <p>Fields with * are mandatory</p>
            <table>
              <tbody>
              <tr><td>Email address *</td>
              <td><input className="bp3-input .modifier" type="text" onChange={this.handleQuoteEmail} value={this.getFromState("requestQuoteEmail", "")} dir="auto" /></td></tr>
              <tr><td>Name *</td>
              <td><input className="bp3-input .modifier" type="text" onChange={this.handleQuoteName} value={this.getFromState("requestQuoteName", "")} dir="auto" /></td></tr>
              <tr><td>Telephone (with country code)</td>
              <td><input className="bp3-input .modifier" type="text" onChange={this.handleTelephone} value={this.getFromState("requestQuoteTelephone", "")} dir="auto" /></td></tr>
              <tr><td>Country *</td>
              <td><input className="bp3-input .modifier" type="text" onChange={this.handleCountry} value={this.getFromState("requestQuoteCountry", "")} dir="auto" /></td></tr>
              <tr><td>State</td>
              <td><input className="bp3-input .modifier" type="text" onChange={this.handleState} value={this.getFromState("requestQuoteState", "")} dir="auto" /></td></tr>
              <tr><td>Project name *</td>
              <td><input className="bp3-input .modifier" type="text" onChange={this.handleQuoteProjectName} value={this.getFromState("requestQuoteProjectName","")} dir="auto" /></td></tr>
              </tbody>
            </table>
            <p>Project brief *</p>
            <TextArea
                large={true}
                intent={Intent.PRIMARY}
                onChange={this.handleQuoteNotes}
                value={this.state.requestQuoteNotes}
            />
            <div className="dialog-buttons">
              <Button text="Send request" disabled={!quoteParametersReady} onClick={this.sendQuote} intent={Intent.PRIMARY} />
              <Button text="Reset" onClick={this.resetQuote} intent={Intent.WARNING} />
              <Button text="Cancel" onClick={this.cancelQuote} intent={Intent.NONE} />
            </div>
          </div>
        </Dialog>
        <Dialog isOpen={this.state.requestQuoteConfirmOpen}>
          <div className={Classes.DIALOG_BODY}>
            <h3>{this.state.requestQuoteConfirmText}</h3>
            <Button text="Close" onClick={() => { this.setState({ requestQuoteConfirmOpen : false })}} intent={Intent.PRIMARY} />
          </div>
        </Dialog>
      </div>
    );
  }

  newConfiguration = (config : IWallParameters) => {
    this.config = config;
    console.log("newConfiguration");
    console.log(this.refs.visualization);
    const visual : any = this.refs.visualization;
    if(visual)
      visual.setState(config);
    const summary : any = this.refs.summary;
    if(summary)
      summary.setState(config);

    if(config) {
      console.log("jQuery = ", $, "Config:", config);
      let str = $.param(config);
      let stateObj = { foo: "bar" };
      history.replaceState(stateObj, "iWall builder", "builder?" + str);
      let iwall = new IWall(config);
      document.title = iwall.labelStr();
    }
  }

  requestQuote = () => {
    this.setState({ requestQuoteDialogOpen : true });
  }
  resetQuote = () => {
    this.setState({ requestQuoteEmail : "", requestQuoteName : "",
      requestQuoteNotes : "", requestQuoteProjectName : ""})
  }
  cancelQuote = () => {
    this.setState({ requestQuoteDialogOpen : false });
  }
  handleQuoteEmail = (event : any) => {
    let str : string = event.target.value;
    this.setState({ requestQuoteEmail : str});
  }
  handleQuoteName = (event : any) => {
    let str : string = event.target.value;
    this.setState({ requestQuoteName : str});
  }
  handleTelephone = (event : any) => {
    let str : string = event.target.value;
    this.setState({ requestQuoteTelephone : str});
  }
  handleCountry = (event : any) => {
    let str : string = event.target.value;
    this.setState({ requestQuoteCountry : str});
  }
  handleState = (event : any) => {
    let str : string = event.target.value;
    this.setState({ requestQuoteState : str});
  }
  handleQuoteNotes = (event : any) => {
    let notes : string = event.target.value;
    this.setState({ requestQuoteNotes : notes});
  }
  handleQuoteProjectName = (event : any) => {
    let notes : string = event.target.value;
    this.setState({ requestQuoteProjectName : notes});
  }
  quoteSent(ok : boolean) {
    if(ok) {
      this.setState({
        requestQuoteNotes : "",
        requestQuoteProjectName : "",
        requestQuoteDialogOpen : false,
        requestQuoteConfirmOpen : true,
        requestQuoteConfirmText : "iWall quotation request sent successfully"
      });
    } else {
      this.setState({
        requestQuoteDialogOpen : false,
        requestQuoteConfirmOpen : true,
        requestQuoteConfirmText : "Could not send iWall quotation request, please contact sales@multitaction.com"
      });
    }
  }

  sendQuote = () => {
    let self = this;
    let values : any = this.state;
    values.iWallURL = window.location.href;
    $.ajax({
      url : '/iwall/api/request-quote',
      type : 'post',
      data : values,
      success : function(data : any) {
        self.quoteSent(data.ok);
      },
      error : function (xhr, ajaxOptions, thrownError) {
        self.quoteSent(false);
      }
    });
  }

  componentDidMount() {
    let params : any = this.refs.parameters;
    if(params)
      this.newConfiguration(params.state);
  }

}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

ReactDOM.render(<IWallBuilder/>, document.getElementById('iwall-builder'));

declare let module: any

if (module.hot) {
    module.hot.accept();
}
